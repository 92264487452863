import {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Paper } from '@mui/material';

import SearchIcon from './svgComponents/SearchIcon';
import DeleteIcon from './svgComponents/DeleteIcon';

export default function Search({value = "", onChange = () => {}, setSearchValue}) {

  const [border, setBorder] = useState(null)

  return (
        <Paper
            component="form"
            sx={{
              border: border,
              padding: '2px 20px',
              display: 'flex',
              alignItems: 'center',
              width: 'calc(1600/1920*100vw)',
              boxSizing: 'border-box',
              height: '3.1vw',
            }}
            square
            elevation={0}
            >
          <Input
            elevation={5}
            placeholder='Поиск контрагента, название или БИН'
            // type='search'
            clearIcon={<DeleteIcon/>}
            fullWidth
            onBlur={() => setBorder(null)}
            onFocus={() => setBorder('1px solid #8AB528')}
            disableUnderline
            value={value}
            inputProps={{
              onKeyDown: (e) => {
                    if (e.key === 'Enter') {                
                      e.preventDefault();                      
                    }
              },
            }}
        
            onChange={onChange}
            sx={{height: '100%',fontSize:'0.9vw'}}
            endAdornment={
              <InputAdornment position="end">
                {value ? <IconButton onClick={()=>setSearchValue({})} type="button" sx={{ padding: '0.52vw' }} aria-label="search" disableRipple>
                    <DeleteIcon  />
                </IconButton> : ""}

                <IconButton type="button" sx={{ padding: '0.52vw' }} aria-label="search" disableRipple>
                    <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Paper>
  );
}