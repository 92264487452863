import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";

import api from "../api/api";
import SideBar from '../component/SideBar';
import Content from '../component/Content';
import ModalWelcome from '../component/Modal/ModalWelcome';
import {TabsContextProvider} from "../helpers/TabsContext";
import { UserContextProvider } from '../helpers/UserContext';
import { ScrollContextProvider } from '../helpers/ScrollContext';

const UserPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const getList = async () => {
            await api.get('/get-list');
        };
        void getList();
    }, [])

    useEffect(() => {
        const findUser = async () => {
            try {
                const res = await api.get(`/find-user`);
                if (res?.data) {
                    setUser(res.data);
                };
            } catch(e) {
                alert(e);
                navigate('/login');
            };
        }
        void findUser();
    }, [])
   
    return (
        <ScrollContextProvider>
            <UserContextProvider user={user}>
                <TabsContextProvider>
                    <div className='user-page'>
                        <SideBar />
                        <Content />
                        <ModalWelcome 
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            user={user}
                            />
                    </div>    
                </TabsContextProvider>
            </UserContextProvider>
        </ScrollContextProvider>
    );
};

export default UserPage;