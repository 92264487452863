import React, {useContext, useEffect, useState, memo} from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import api from '../api/api';
import {TabsContext} from "../helpers/TabsContext";
import { UserContext } from '../helpers/UserContext';
import Badge from '../component/svgComponents/Badge';

import Search from './Search';
import HeaderTableCounterParty from './CounterPartyComponent/HeaderTableCounterParty';
import TableCounterParty from './CounterPartyComponent/TableCounterParty';
import HeaderTableCounterPartyWait from './CounterPartyComponent/HeaderTableCounterPartyWait';
import TableCounterPartyWait from './CounterPartyComponent/TableCouterPartyWait';
import NoWaitCounterParty from './CounterPartyComponent/NoWaitCounterParty';
import NoCounterParty from './CounterPartyComponent/NoCounterParty';
import NoInvite from './CounterPartyComponent/NoInvite';
import NewCounterparty from './CounterPartyComponent/NewCounterparty';
import InviteCounterParty from './CounterPartyComponent/InviteCounterParty';
import Loader from "./DocumentComponents/Loader";


const ACCEPT_COUNTER_PARTY_STATUS = 6;
const NOT_ACCEPT_COUNTER_PARTY_STATUS =[1, 2, 3, 4, 5, null];
const WAITING_COUNTER_PARTY_STATUS =[1, 2, 3, null];

const REJECTED_COUNTER_PARTY_STATUS =[4, 5, null];


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span style={{width: props.isNewTab ? "65%" : '80%', background:'#8AB528',zIndex:503}} className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
      display: 'flex', 
      width: '4.9vw',  
      height:'0.52vw',
      justifyContent: 'center',
      backgroundColor:'rgba(0, 0, 0, 0)',
      maxWidth: '100%',
      zIndex:504,
      marginLeft:'-1.5vw'      
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    height: '5.2vw',
    fontSize: '1.25vw',
    color: '#181818',
    paddingLeft:0,
    paddingRight:'3vw',
    '&.Mui-selected': {
      color: '#181818',
      fontWeight: '700',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

const customStyleUndo = {
  marginTop:'1.04vw'
}
const customStyleUpper = {
  marginTop:'1.04vw',
  paddingBottom:'6.5vw'
}

  let rowsWait=[];


  function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={ !rowsWait.length ? customStyleUndo : customStyleUpper }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default memo(function BasicTabs() {
  const [counterpartiesConnect, setCounterpartiesConnect] = useState([]);
  const [counterpartyWait, setCounterpartyWait] = useState([]);
  const [counterpartyNotAccept, setCounterpartyNotAccept] = useState([]);
  const countCounterpartyWait= (counterpartyWait.length);
  const countCounterpartyNotAccept= ((counterpartyNotAccept.filter((item)=> REJECTED_COUNTER_PARTY_STATUS.indexOf(item.status)===-1)).length);

  const [counterpartiesFilteredConnect, setCounterpartiesFilteredConnect] = useState([]);
  const [counterpartyFilteredWait, setCounterpartyFilteredWait] = useState([]);
  const [counterpartyFilteredNotAccept, setCounterpartyFilteredNotAccept] = useState([]);

  const [isCounterPartyChange, setIsCounterPartyChange] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const { user} = useContext(UserContext) 
  const [searchValue, setSearchValue] = useState({
    counterpartiesConnect: "",
    counterpartyWait:"",
    counterpartyNotAccept: "",
  });
  const returnObject=(data, isNotAccept = false)=>{ 
    return {
      company: data.counterparty_name,
      date: data.created_at,
      bin: data.counterparty_identification_number,
      status: data.counterparty_status_id === 6 ? data.my_status_ic : data.counterparty_status_id,
      recipient_id: data.counterparty_id
    }
  }

  const {
    currentPageTab,
    setCurrentPageTab
  } = useContext(TabsContext);  
  
  const handleChange = (event, newValue) => {    
    localStorage.setItem('Tab', JSON.stringify({"currentTab":1,"currentPageTab":newValue}));
    setCurrentPageTab(newValue);
  };

  const asyncHandler = async () => {
    const res = await api.get(`/get-counterparties`)
    const responseData = res?.data;
    if (responseData) {
      setIsContentLoaded(true);
      const userId = user.id;
      const filteredConnectedCounterParty = responseData.filter(item => {
        return item.counterparty_status_id === ACCEPT_COUNTER_PARTY_STATUS && 
          item.my_status_id === ACCEPT_COUNTER_PARTY_STATUS
      }); 
      
      const filteredWaitingCounterParty = responseData.filter(item => {
        return WAITING_COUNTER_PARTY_STATUS.includes(item.my_status_id)
      });

      const filteredNotAcceptCounterParty = responseData.filter(item => {
        return (item.counterparty_status_id !== ACCEPT_COUNTER_PARTY_STATUS && item.my_status_id === ACCEPT_COUNTER_PARTY_STATUS) ;
      });
      setCounterpartiesConnect(filteredConnectedCounterParty.map(data => returnObject(data)))
      
      setCounterpartyWait(filteredWaitingCounterParty.map(data => returnObject(data)))

      setCounterpartyNotAccept(filteredNotAcceptCounterParty.map(data => returnObject(data)))
    }
    
  }

  useEffect(() => {
    setIsContentLoaded(true)
    void asyncHandler();
    
    if (isCounterPartyChange) {
      setIsCounterPartyChange(false);
    }
    setIsContentLoaded(false)
    return () => {
      // clear state
      setCounterpartyWait([]);
      setIsCounterPartyChange(false);
    }
  }, [isCounterPartyChange,
     currentPageTab])

  useEffect(() => {
    setCounterpartiesFilteredConnect(counterpartiesConnect.filter((data) => {
      return Object.values(data).some(value => value ? value?.toString().toUpperCase().includes(searchValue?.counterpartiesConnect?.toUpperCase()) : false)
    }))
  }, [searchValue.counterpartiesConnect, counterpartiesConnect]);

  useEffect(() => {
    setCounterpartyFilteredWait(counterpartyWait.filter((data) => {
      return Object.values(data).some(value => value ? value?.toString().toUpperCase().includes(searchValue?.counterpartyWait?.toUpperCase()) : false)
    }))
  }, [searchValue.counterpartyWait, counterpartyWait]);
 

  useEffect(() => {
    setCounterpartyFilteredNotAccept(counterpartyNotAccept.filter((data) => {
      return Object.values(data).some(value => value ? value?.toString().toUpperCase().includes(searchValue?.counterpartyNotAccept?.toUpperCase()) : false)
    }))
  }, [searchValue.counterpartyNotAccept, counterpartyNotAccept]);

  return (
    <Box sx={{ width: '100%'}}>
      <Box sx={{ width: 'calc(1600/1920*100vw)' }}>  
        <StyledTabs isNewTab={currentPageTab === 0} value={currentPageTab} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab label="+Новый" {...a11yProps(0)} />
          <StyledTab label="Ваши контрагенты" {...a11yProps(1)} />
          <StyledTab 
          label="Приглашают вас" 
          {...a11yProps(2)}
            icon={countCounterpartyWait ? <Badge content={countCounterpartyWait}/> : null} 
            iconPosition="end"
           />
          <StyledTab
            label="Ожидается ответ"
            {...a11yProps(3)}
            icon={countCounterpartyNotAccept ? <Badge content={countCounterpartyNotAccept}/> : null} 
            iconPosition="end"
            />
        </StyledTabs>
        <hr style={{height:'2px', background: '#BDBDBD', border: 'none', position: 'relative', bottom:'2px',zIndex:1}} />
      </Box>
      <TabPanel value={currentPageTab} index={0}>
        <NewCounterparty refetchData={asyncHandler}/>   
      </TabPanel>
      <TabPanel value={currentPageTab} index={1}>
        { !counterpartiesConnect.length && !searchValue.counterpartiesConnect ? (
          <>
             {!isContentLoaded? <Loader />
              : <NoCounterParty />
             }
          </>
          ) : (
          <>
            <Search
              setSearchValue={setSearchValue}
              value={searchValue.counterpartiesConnect} 
              onChange={(e) => {
              setSearchValue(prevState => ({...prevState, counterpartiesConnect: e.target.value}));
            }}
            />
            <HeaderTableCounterParty/>
            <TableCounterParty rows={searchValue.counterpartiesConnect ? counterpartiesFilteredConnect :counterpartiesConnect}/>
          </>
      )}
      </TabPanel>
      <TabPanel value={currentPageTab} index={2}>
        { !counterpartyWait.length && !searchValue.counterpartyWait ? (
          <>
            {!isContentLoaded? <Loader />
            : <NoInvite />
            }
          </>
          ) : (
          <>
            <h1 className='invite-header'>Приглашают вас к международному ЭДО</h1>
          <Search 
            setSearchValue={setSearchValue}
            value={searchValue.counterpartyWait} 
            onChange={(e) => {
              setSearchValue(prevState => ({...prevState, counterpartyWait: e.target.value}));
            }}
          />
          <InviteCounterParty
            refetchData={asyncHandler}
            counterInviteParty={searchValue.counterpartyWait ? counterpartyFilteredWait :counterpartyWait}
            setIsCounterPartyChange={setIsCounterPartyChange}
          />
        </>)}
      </TabPanel>
      <TabPanel value={currentPageTab} index={3}>
        { !counterpartyNotAccept.length && !searchValue.counterpartyNotAccept ? (
          <>
            {!isContentLoaded? <Loader />
            : <NoWaitCounterParty />
            }
          </>
          ) : (
          <>
           <Search 
              setSearchValue={setSearchValue}
              value={searchValue.counterpartyNotAccept} 
              onChange={(e) => {
                setSearchValue(prevState => ({...prevState, counterpartyNotAccept: e.target.value}));
              }}
            />
           <HeaderTableCounterPartyWait/>
           <TableCounterPartyWait rows={searchValue.counterpartyNotAccept ? counterpartyFilteredNotAccept : counterpartyNotAccept}/>
          </>
        )}
      </TabPanel>
    </Box>
  );
});