import React, { useState,useLayoutEffect } from 'react'
import {Link,useNavigate, useSearchParams} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import validator from 'validator';

import api from "../api/api";
import Header from "../component/Headers/Header";
import ClosedEye from '../component/svgComponents/ClosedEye';
import OpenedEye from '../component/svgComponents/OpenedEye';

const MIN__LENGTH = 3;
const MAX__LENGTH = 15;
const INN__LENGTH = [10, 12];
const KPP__LENGTH = 9;
const MESSAGE_EMAIL_IS_INVALID = "Email не соответствует требованиям";
const MESSAGE_INN_REQUIRED = "Введите ИНН компании должен состоять из 10 или 12 символов";
const MESSAGE_KPP_REQUIRED = "Введите КПП компании должен состоять из 9 символов";
const MESSAGE_PASSWORD= "Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов";
const MESSAGE_PASSWORDS_MATCH = "Пароли должны совпадать";
const MESSAGE_NAME_REQUIRE = "Введите название компании";
const MESSAGE_MAX_SYMBOL = "Максимальное количество символов 15";


export default function RegisterPage () {
    const navigate = useNavigate();
    const [netWorkError,setNetWorkError] = useState()
    const [serverError,setServerError] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    useLayoutEffect(() => {  
      let formSelector = document.getElementsByClassName("form-container")[0]
      formSelector.setAttribute("style", "flex-direction: row;")
      setTimeout(()=> {
        formSelector.removeAttribute("style")
      }, 0)      
    }, []) 

    const initialErrorsList = {
      name: "",
      email: "",
      inn: "",
      kpp: "",
      password: "",   
      password2:"",         
    }
 
    const [errorsList, setErrorsList] = useState(initialErrorsList)
    const [searchParams] = useSearchParams();
    const [register, setRegister] = useState(() => {
        return {
            name: "",
            email: searchParams.get("email")||"",
            inn:searchParams.get("identification_number")||"",
            kpp:"",
            password: "", 
            password2:"",             
        }
    })

    const removeFieldError = (field) => {
        setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
    } 

    const changeInputRegister = event => {   
        const changedValue = event.target.value
        const fieldName = event.target.name
        removeFieldError(fieldName);
        setRegister(prev => {
            return {
                ...prev,
                [fieldName]: changedValue,
            }
        })
    }

    const submitCheck = async (event) => {
      event.preventDefault();
      let wasError = false;
      const expression = /\s/g;
      const {
        name,
        inn,
        kpp,
        password,
        password2
      } = register;
      const email = register.email.trim();

      if (name.length <= MIN__LENGTH) {
        setErrorsList(prevState => ({...prevState, name: MESSAGE_NAME_REQUIRE}))
        wasError = true;
      }
      if (!validator.isEmail(email)) {
        setErrorsList(prevState => ({...prevState, email: MESSAGE_EMAIL_IS_INVALID}))
        wasError = true;
      }
      if (!INN__LENGTH.includes(inn.length) && inn.match(expression).length) {
        setErrorsList(prevState => ({...prevState, inn: MESSAGE_INN_REQUIRED}))
        wasError = true;
      }
      if (kpp.length !== KPP__LENGTH && kpp.match(expression).length) {
        setErrorsList(prevState => ({...prevState, kpp: MESSAGE_KPP_REQUIRED}))
        wasError = true;
      }
      if (!validator.isStrongPassword(password, {minSymbols: 0})) {
        setErrorsList(prevState => ({...prevState, password: MESSAGE_PASSWORD}))
        wasError = true;
      }
      if (password2 !== password) {
        setErrorsList(prevState => ({...prevState, password2: MESSAGE_PASSWORDS_MATCH}))
        wasError = true;
      }
      if (wasError) {
        return;
      }
      try {
        const res = await api.post(
          "/auth/register/", {
           name: register.name,
           identification_number: register.inn,
           kpp: register.kpp,
           email: register.email,
           password: register.password,
        });
        if (res?.data) {
          navigate('/finish-register',{state:{email:email}});
        }
      } catch (e) {
        if(e.message === "Network Error") {          
          setNetWorkError(e.message);
         };
        setServerError(e.response?.data?.error);       
      }
    }


    return (
        <>       
        <Header/>  
        <Formik            
          initialValues={register}
          onSubmit={submitCheck}
        >
          <div className='form-wrapper'>
            <Form className='form-container form-container-register'  >
              <h1 className='form-title'>Регистрация</h1>
              <h6 className='form-subtitle'>Заполните поля и начните пользоваться сервисом международного ЭДО SimpSign Online</h6>

              <div className='field-container'>
                <p className="field-title">
                  Название компании
                </p>            
                <Field
                  className={Boolean(errorsList?.name) ? "input_error": "input"}
                  label="name"
                  name="name"
                  type="input"
                  onChange={changeInputRegister}
                  value={register.name}
                />         
                {Boolean(errorsList?.name) ? (<div className="valid-error">{errorsList.name}</div>) : null} 
              </div>

              <div className='field-container'>
                <p className="field-title">Email</p> 
                <Field
                  className={Boolean(errorsList?.email) || serverError?.email ? "input_error": "input"}
                  label="Email"
                  name="email"
                  type="email" 
                  onChange={changeInputRegister}              
                  value={register.email}            
                />
                {Boolean(errorsList?.email) ? (<div className="valid-error">{errorsList.email}</div>) : null}   
                {(<div className="valid-error">{serverError==='Данный E-Mail уже используется' ? "Email уже используется":""}</div>)}

              </div>
                
              <div className='field-container'>
                <p className="field-title">ИНН</p>
                <Field
                className={Boolean(errorsList?.inn) || serverError?.inn ? "input_error": "input"}
                  label="inn"
                  name="inn"
                  type="text" 
                  onChange={changeInputRegister}
                  value={register.inn}        
                />
                {Boolean(errorsList?.inn) ? (<div className="valid-error">{errorsList.inn}</div>) : null}   
                {(<div className="valid-error">{serverError==='Данная комбинация ИНН и КПП уже используется'? "Данная комбинация ИНН и КПП уже используется":""}</div>)}

              </div>

              <div className='field-container'>
                <p className="field-title">КПП</p>
                <Field
                  className={Boolean(errorsList?.kpp) || serverError?.kpp ? "input_error": "input"}
                  label="kpp"
                  name="kpp"
                  type="text" 
                  onChange={changeInputRegister}
                  value={register.kpp}        
                />
                {Boolean(errorsList?.kpp) ? (<div className="valid-error">{errorsList.kpp}</div>) : null}   
                {(<div className="valid-error">{serverError==='Данная комбинация ИНН и КПП уже используется' ? "Данная комбинация ИНН и КПП уже используется":""}</div>)}
              </div>

              <div className='field-container'>
                <p className="field-title">Пароль</p>
                  <Field
                    className={Boolean(errorsList?.password) || serverError?.password ? "input_error": "input"}
                    label="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}   
                    onChange={changeInputRegister}
                    value={register.password}     
                  />
                  {
                    <div                    
                    className={Boolean(errorsList?.password) || serverError?.password ? "password-image-error password-image-error-register": "password-image"}
                    onClick={() => setPasswordVisible(prevState => !prevState)}
                    >
                      {
                        passwordVisible
                          ? <OpenedEye/>
                          : <ClosedEye />
                      }
                    </div>
                  }
                  {Boolean(errorsList?.password) ? (<div className="valid-error">{errorsList.password}</div>) : null}  
              </div>            

            <div className='field-container'>
              <p className="field-title">Повторить пароль</p>

              <Field
                className={Boolean(errorsList?.password2) || serverError?.password2 ? "input_error": "input"}
                label="password2"          
                name="password2"
                type={passwordVisible2 ? "text" : "password"}   
                onChange={changeInputRegister}
                value={register.password2}      
                />    
                {
                  <div
                  className={Boolean(errorsList?.password2) || serverError?.password2 ? "password-image-error password2-image-error-register": "password-image"}
                  onClick={() => setPasswordVisible2(prevState => !prevState)} 
                  >
                    {
                      passwordVisible2
                        ? <OpenedEye/>
                        : <ClosedEye />
                    }
                  </div>
                }
              {Boolean(errorsList?.password2) ? (<div className="valid-error">{errorsList.password2}</div>) : null}         
              
            </div>

              <div className="form-buttons-container">
                <button
                  className="form-button"
                  type="submit"
                onClick={submitCheck}
                >
                  Зарегистрироваться
                
                </button>
                {netWorkError && (
                  <p className="form-error">
                    <span className="valid-error">
                      На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.
                    </span>
                  </p>
                )}
              </div>
              <div className="accept">
                <p>
                  Регистрируясь в сервисе вы принимаете условия
                </p>
                <div>
                  <a
                    style={{color:"#000000"}}
                    href='https://simpsign.com/terms-and-conditions' 
                    target='blank'
                  >
                      пользовательского соглашения
                  </a>
                  .
                </div>
              </div>
              <Link className='form__link' to="/">Уже зарегистрированы? Войти</Link>
            </Form>
            <div className='footer'>
              <div className='footer__register'>
                <p>Узнать больше о сервисе SimpSign</p>
                <a href='https://simpsign.com/'>www.simpsign.com</a>
              </div>
            </div> 
          </div>          
        </Formik>

      </>
    )    
}



