import React from "react";

function StampTemplate({
  sender,
  recipient,
}) {
  return (
    <>
      <style>
        {`
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdzeFb5N.woff2) format('woff2');
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
          }
          
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdXeFb5N.woff2) format('woff2');
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
          }
          
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdLeFb5N.woff2) format('woff2');
            unicode-range: U+0370-03FF;
          }
          
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhd7eFb5N.woff2) format('woff2');
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
          }
          
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhd_eFb5N.woff2) format('woff2');
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
         
          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdHeFQ.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIxsdO_q.woff2) format('woff2');
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIVsdO_q.woff2) format('woff2');
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdO_q.woff2) format('woff2');
            unicode-range: U+0370-03FF;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI5sdO_q.woff2) format('woff2');
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI9sdO_q.woff2) format('woff2');
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }

          @font-face {
            font-family: 'IBM Plex Sans';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdA.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }

        * {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }
        
        .stamp-page {
          display: flex;
          justify-content: space-between;
          font-family: 'IBM Plex Sans';
        }
        
        .stamp-list {
          display: flex;
          width: 350px;
          flex-direction: column;
          row-gap: 10px;
        }

        .list-header {
          align-self: center;
          text-decoration: underline;
          font-size: 13px;
        }
        
        .list-header {
          text-decoration underline;
        }
        
        .stamp {
          display: flex;
          column-gap: 15px;
          padding: 15px 0 5px 30px;
          border: 3px solid #218C21;
          width: 350px;
        }

        .stamp.error {
          color: red;
        }
        
        .logo {
          width: 50px;
          align-self: flex-start;
        }
        
        .info h3 {
          font-size: 10px;
        }
        
        .info p {
          font-size: 7px;
        }
        
        .company p {
          min-height: 30px;
          margin-bottom: 7px;
        }
        
        .certificate p:last-child {
          margin-bottom: 7px;
        }
        `}
      </style>
      <div className='stamp-page'>
        <div className='stamp-list'>
          <h3 className='list-header'>Отправитель</h3>
          { sender.map((stamp, index) => (
            <div className={`stamp ${!(stamp.result) && 'error'}`} key={index}>
              <img className='logo' alt='logo' src="./assets/image/stamp-logo.svg"/>
              <div className='signer-info'>
                <div className='company info'>
                  <h3 className='block-header'>{
                    index
                      ? (stamp.result ? 'Проверено' : 'Проверка не пройдена')
                      : 'Подписано электронной подписью'
                  }</h3>
                  <p>{ stamp.userData }</p>
                </div>
                <div className='certificate info'>
                  <h3 className='block-header'>Сертификат</h3>
                  <p>{ stamp.serialNumber }</p>
                  <p> {`срок действия с ${stamp.validFrom} до ${stamp.validTo}`} </p>
                </div>
                <div className='date info'>
                  <h3 className='block-header'>{ `Дата и время ${ index ? 'проверки' : 'подписи' }` }</h3>
                  <p>{ stamp.date.toUTCString() }</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        { recipient && (<div className='stamp-list'>
          <h3 className='list-header'>Получатель</h3>
          { recipient && recipient.map((stamp, index) => (
            <div className={`stamp ${!(stamp.result) && 'error'}`} key={index}>
              <img className='logo' alt='logo' src="./assets/image/stamp-logo.svg"/>
              <div className='signer-info'>
                <div className='company info'>
                  <h3 className='block-header'>{
                    index
                      ? (stamp.result ? 'Проверено' : 'Проверка не пройдена')
                      : 'Подписано электронной подписью'
                  }</h3>
                  <p>{ stamp.userData }</p>
                </div>
                <div className='certificate info'>
                  <h3 className='block-header'>Сертификат</h3>
                  <p>{ stamp.serialNumber }</p>
                  <p> {`срок действия с ${stamp.validFrom} до ${stamp.validTo}`} </p>
                </div>
                <div className='date info'>
                  <h3 className='block-header'>{ `Дата и время ${ index ? 'проверки' : 'подписи' }` }</h3>
                  <p>{ stamp.date.toUTCString() }</p>
                </div>
              </div>
            </div>
          ))}
        </div>)}
      </div>
    </>
  )
}

export default StampTemplate;