import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EmailSend from "./pages/EmailSend";
import UserPage from "./pages/UserPage";
import FinishRegister from "./pages/FinishRegister";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registration" element={< RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email-send" element={<EmailSend />} />
        <Route path="/user-page" element={<UserPage />} />
        <Route path="/finish-register" element={<FinishRegister />} />
        <Route path="*" element={<Navigate to="/user-page" replace={true} />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
