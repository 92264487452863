import React, {useContext, useEffect, useRef} from 'react';

import {navigateTabFromLocalStorage, TabsContext} from "../helpers/TabsContext";
import { ScrollContext } from '../helpers/ScrollContext';

import HeaderContent from './Headers/HeaderContent';
import Document from './Document';
import CounterParty from './CounterParty';
import Settings from './Settings';
import { useScrollTrigger } from '@mui/material';

const Content = (props) => {
    const {currentTab, setCurrentPageTab, setCurrentTab} = useContext(TabsContext);    
    const {scroll,setScroll} = useContext(ScrollContext);    
    const Node = useRef(null);
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      target: Node.current || undefined,
      threshold: 262,
    });
    setScroll(trigger)

    useEffect(() => {
      navigateTabFromLocalStorage(setCurrentTab, setCurrentPageTab)
    }, [])

    return (
        <div className='content' ref={Node}>
            <HeaderContent
            />                
            {(() => {
            switch (currentTab) {
              case 0:
                return <Document/>        
              case 1:
                return <CounterParty  />
              case 2:
                    return <Settings/>    
              default:
                return null
            }
            })()}
        </div>
    );
};

export default Content;