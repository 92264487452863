import React from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '27.05vw',
    height: '13.15vw',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans', 
    boxSizing: 'border-box',
    outline: 'none'
  };

export default function ModalWaiting({isOpen, setIsOpen, documentsID, docsCount}) {
    return (
        <div>
            <Modal
            open={isOpen}
        >
            <Box sx={style}>
                {docsCount ?
                    <div className='signature-waiting-text'>Идёт подписание пакетов <br/> документов. Осталось: {docsCount} <br/> Пожалуйста, не закрывайте страницу</div>
                :
                    <div className='signature-waiting-text'>Идёт подписание пакета <br/> документов<br/> Пожалуйста, не закрывайте страницу</div>
                }
            </Box>
        </Modal>
        </div>
    )
}
