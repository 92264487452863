import React, {useContext, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

import CancelIcon from '../svgComponents/CancelIcon';
import CancelIconDisabled from '../svgComponents/CancelIconDisabled';
import TrashIcon from '../svgComponents/TrashIcon';
import TrashIconDisabled from '../svgComponents/TrashIconDisabled';
import DownloadIcon from '../svgComponents/DownloadIcon';
import DownloadIconDisabled from '../svgComponents/DownloadIconDisabled';
import Signature from '../svgComponents/Signature';
import SignatureDisabled from '../svgComponents/SignatureDisabled';
import ModalSign from '../Modal/ModalSign';
import ModalSignSending from '../Modal/ModalSignSending';
import ModalWaiting from '../Modal/ModalWaiting';
import ModalError from '../Modal/ModalError';
import ModalChoose from '../Modal/ModalChoose';

import globalStyle from './customStyleButton';
import { ScrollContext } from '../../helpers/ScrollContext';

const CustomButton = styled(Button)(({ theme }) => ({
   color: '#181818',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: '#BDBDBD',
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function ButtonsPanelInComing(
  {
    documentsID,
    setDocumentsID,
    uncheckAll,
    downloadFiles,
    declineFiles,
    deleteFiles,
    dataURLtoFile,
    refetch,
    isButtonsDisabled,
    readFileRecursive,
    signOrDeclineButtonActive,
    isScrolled,
    isDownloading,
  }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChosen, setIsOpenChosen] = useState(false);
  const [isOpenSigned, setIsOpenSigned] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const [docsCount, setDocsCount] = useState(1);
  const [error, setError] = useState(null);
  const [modalType, setModalType] = useState('');
  const {scroll} = useContext(ScrollContext);

  const columns = [
    { id: 'check',
      label: '',
      width: '6%',
    },
    { id: 'date',
      label: 'Дата',
      width:'9%',
    },
    { id: 'sender',
      label: 'Отправитель',
      width:'16%',
     },
    {
      id: 'document',
      label: 'Пакет документов, ID',
      width: '24%',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'statusDocument',
      label: 'Статус пакета документов',
      width:'17%',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'signatureStatus',
      label: 'Статус подписи',
      width: '15%',
      format: (value) => value,
    },
    {
      id: 'shelfLife',
      label: 'Срок хранения пакета',
      width: '13%',
      format: (value) => value.toFixed(2),
    }
  ];

  return (
    <Box  sx={{boxShadow: scroll ? "0px 3px 5px rgba(0, 0, 0, 0.2)" : 'none', pt:'20px',position:"sticky",top: 0, marginLeft: "-2.08vw", paddingLeft: "2.08vw",  zIndex: 1, background: '#e5e5e5', width:'100%'}}>
        <Stack direction='row' spacing={3}>
            <CustomButton
              disableRipple
              onClick={()=>{
                if (error) {
                  setIsOpenError(!isOpen)
                } else {
                  setModalType('Sign')
                  setIsOpenChosen(!isOpen);
                }
              }}
              variant="contained"
              disabled={!signOrDeclineButtonActive || !documentsID?.length || isButtonsDisabled}
              sx={globalStyle.customStyleButton}
              startIcon={signOrDeclineButtonActive ? <Signature/> : <SignatureDisabled/>}
            >
              Подписать
            </CustomButton>
          <CustomButton
            disableRipple
            variant="contained"
            disabled={!signOrDeclineButtonActive || !documentsID?.length} // check disabled
            sx={globalStyle.customStyleButton}
            startIcon={signOrDeclineButtonActive ? <CancelIcon/> : <CancelIconDisabled/>}
            onClick={()=> {
              declineFiles();
            }}
          >
            Отказать
          </CustomButton>

          <CustomButton
            disableRipple
            variant="contained"
            disabled={
              !documentsID?.length
                || isButtonsDisabled
                || isDownloading
              }
            sx={globalStyle.customStyleButton}
            startIcon={
              !documentsID?.length || isButtonsDisabled
                ? <DownloadIconDisabled/>
                : !isDownloading
                ? <DownloadIcon/>
                : undefined
              }
            onClick={()=> {
              downloadFiles();
            }}
          >
            { isDownloading
              ? <CircularProgress
                  size="1.5rem"
                  sx={{
                    color: "#949DAF",
                    padding: "0.5rem"
                  }}
                />
              : "Скачать"
            }
          </CustomButton>

          <CustomButton
              disableRipple
              onClick={()=>{
                  setModalType('Delete');
                  setIsOpenChosen(!isOpen);
              }}
              disabled={!documentsID?.length}
              variant="contained"
              sx={globalStyle.customStyleButton}
              startIcon={documentsID?.length ? <TrashIcon/> : <TrashIconDisabled/>}
          >
              Удалить
          </CustomButton>

        </Stack>
        <Stack sx={{...globalStyle.styleStack,borderBottom: scroll? "" : "1px solid #E1E4E8"}}>
            {columns.map((column) => (
              <Box width={column.width} height={'50px'} justifyContent={'left'} display={'flex'} key={column.id}alignItems={'end'} paddingBottom={'9px'}>
                <Typography
                  variant='inherit'
                  sx={globalStyle.styleHead}
                  key={column.id}
                >
                  {column.label}
                </Typography>
              </Box>
            ))}
        </Stack>
        <ModalSign
          uncheckAll={uncheckAll}
          isOpen ={isOpen}
          setIsOpen={setIsOpen}
          setIsOpenSigned={setIsOpenSigned}
          setIsOpenWaiting={setIsOpenWaiting}
          setError={setError}
          documentsID={documentsID}
          setDocumentsID={setDocumentsID}
          dataURLtoFile={dataURLtoFile}
          setDocsCount={setDocsCount}
          refetch={refetch}
          readFileRecursive={readFileRecursive}
        />
        <ModalWaiting
          isOpen ={isOpenWaiting}
          setIsOpen={setIsOpenWaiting}
          documentsID={documentsID}
          docsCount={docsCount}
         />
        <ModalChoose
          isOpen={isOpenChosen}
          setIsOpen={setIsOpenChosen}
          documentsID={documentsID}
          setIsOpenCerts={setIsOpen}
          modalType={modalType}
          deleteFiles={deleteFiles}
        />
        <ModalSignSending
          isOpen ={isOpenSigned}
          setIsOpen={setIsOpenSigned}
        />
        <ModalError
          isOpen ={isOpenError}
          setIsOpen={setIsOpenError}
        />
    </Box>

  );
}