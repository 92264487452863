import React, {useContext, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import {  Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

import ValidIcon from '../svgComponents/ValidIcon';
import ValidIconDisabled from '../svgComponents/ValidIconDisabled';
import TrashIcon from '../svgComponents/TrashIcon';
import TrashIconDisabled from '../svgComponents/TrashIconDisabled';
import DownloadIcon from '../svgComponents/DownloadIcon';
import DownloadIconDisabled from '../svgComponents/DownloadIconDisabled';
import ModalChoose from '../Modal/ModalChoose';

import globalStyle from './customStyleButton';
import { ScrollContext } from '../../helpers/ScrollContext';

const CustomButton = styled(Button)(({ theme }) => ({
    color: '#181818',
    '&:hover, &.Mui-focusVisible': {
    backgroundColor: '#BDBDBD',
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function ButtonsPanelOutGoing(
  {
    downloadFiles,
    deleteFiles,
    documentsID,
    isButtonsDisabled,
    revalidateFiles,
    revalidateButtonActive,
    isDownloading
  }) {
  const [isOpen] = useState(false);
  const [isOpenChosen, setIsOpenChosen] = useState(false);
  const [modalType, setModalType] = useState('');
  const {scroll} = useContext(ScrollContext);
  const columns = [
    { id: 'check',
      label: '',
      width: '6%',
    },
    { id: 'date',
      label: 'Дата',
      width:'9%',
    },
    { id: 'sender',
      label: 'Получатель',
      width:'16%',
     },
    {
      id: 'document',
      label: 'Пакет документов, ID',
      width: '24%',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'statusDocument',
      label: 'Статус пакета документов',
      width:'17%',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'signatureStatus',
      label: 'Статус подписи',
      width: '15%',
      format: (value) => value,
    },
    {
      id: 'shelfLife',
      label: 'Срок хранения пакета',
      width: '13%',
      format: (value) => value.toFixed(2),
    }
  ];

  return (
    <Box  sx={{boxShadow: scroll ? "0px 3px 5px rgba(0, 0, 0, 0.2)" : 'none',pt:'20px',position:"sticky",marginLeft: "-2.08vw", paddingLeft: "2.08vw", top: 0, zIndex: 1, background: '#e5e5e5', width:'100%'}}>
        <Stack direction='row' spacing={3} >
          <CustomButton
            disableRipple
            disabled={!documentsID?.length || isButtonsDisabled || isDownloading}
            variant="contained"
            sx={globalStyle.customStyleButton}
            startIcon={
              isButtonsDisabled || !documentsID?.length
                ? <DownloadIconDisabled/>
                : !isDownloading
                ? <DownloadIcon/>
                : undefined
              }
            onClick={()=> {
              downloadFiles();
            }}
          >
            { isDownloading
              ? <CircularProgress
                  size="1.5rem"
                  sx={{
                    color: "#949DAF",
                    padding: "0.5rem"
                  }}
                />
              : "Скачать"
            }
          </CustomButton>
          <CustomButton
              disableRipple
              disabled={!documentsID?.length}
              onClick={()=>{
                  setModalType('Delete');
                  setIsOpenChosen(!isOpen);
              }}
              variant="contained"
              sx={globalStyle.customStyleButton}
              startIcon={documentsID?.length ? <TrashIcon/> : <TrashIconDisabled/>} 
          >
              Удалить
          </CustomButton>
          <CustomButton
            disableRipple
            variant="contained"
            disabled={!revalidateButtonActive || !documentsID?.length || isButtonsDisabled}
            sx={{...globalStyle.customStyleButton,width:'16.7vw'}} 
            startIcon={!isButtonsDisabled && revalidateButtonActive  ? <ValidIcon/> : <ValidIconDisabled/>}
            onClick={revalidateFiles}
          >
            Перевалидировать подпись
          </CustomButton>
        </Stack>
        <Stack sx={{...globalStyle.styleStack,borderBottom: scroll? "" : "1px solid #E1E4E8"}}>
            {columns.map((column) => (
              <Box width={column.width} height={'50px'} justifyContent={'left'}  display={'flex'} key={column.id} alignItems={'end'} paddingBottom={'9px'}>
                <Typography
                  variant='inherit'
                  sx={globalStyle.styleHead}
                  key={column.id}
                >
                  {column.label}
                </Typography>
              </Box>
            ))}
        </Stack>
        <ModalChoose
          isOpen={isOpenChosen}
          setIsOpen={setIsOpenChosen}
          documentsID={documentsID}
          modalType={modalType}
          deleteFiles={deleteFiles}
        />
    </Box>

  );
}