import  React, { useState, useEffect, useContext } from 'react';
import JSZip from 'jszip';
import * as fileSaver from 'file-saver';

import { Autocomplete, Button, IconButton, List, ListItem, styled, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {TabsContext} from "../../helpers/TabsContext";
import api from '../../api/api';
import ClipIcon from '../svgComponents/ClipIcon';
import DeleteIcon from '../svgComponents/DeleteIcon';
import Checkbox from '../svgComponents/Checkbox';
import DownArrow from '../svgComponents/DownArrow'
import ModalSign from '../Modal/ModalSign';
import ModalSignSending from '../Modal/ModalSignSending';
import ModalError from '../Modal/ModalError';
import ModalWaiting from '../Modal/ModalWaiting';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height:'3.1vw',
        }
      }
    }
    
  }
});

const COUNTER_TAB_INDEX = 1; 

const CustomInput = styled(TextField)(({ theme }) => ({
  '.MuiInputLabel-root': {
    display: 'none',
  },
  '.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    fontFamily: 'IBM Plex Sans',
    fontWeight: '400',
    fontSize: '0.83vw',
    height:'3.1vw',
  },
  'label,&.Mui-focused': {
    border: '1px solid ##BDBDB',  
  },
  '&.MuiTextField-root':{
    width:'calc(1600/1920*100vw)',
    background:'#fff',
    fontFamily: 'IBM Plex Sans',
    fontWeight: '400',
    fontSize: '0.83vw',
    height:'3.1vw',
    borderRadius: '0', 
  },
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 }
}));

const CustomButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root':{
      width:'14.5vw',
      height:'3.1vw', 
      color: '#000000', 
      boxSizing: 'border-box',
      padding: '0.93vw 1.04vw',
      border: '1px solid #BDBDBD',
      borderRadius:0,
      boxShadow: 'none',
      backgroundColor:'#e5e5e5',
      fontFamily: 'IBM Plex Sans',
      fontWeight: '400',
      fontSize: '0.83vw',
      lineHeight: '150%', 
      textTransform:'none',
  },
  '&.MuiButtonBase-root:hover':{
    borderColor: 'black'
  },
  '.form-button.button-cancel:hover': {
    borderColor: 'black',
    backgroundColor: 'white',
  }
}));

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({

    "& .MuiAutocomplete-listbox": {
      border: "2px solid grey",
      minHeight: 400,
      color: "green",
      fontSize: 18,
      //hover discussed above
      "& li": {
        //list item specific styling
        border: "2px solid green",
        borderRadius: 4
      }
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      border: 'none',
    },
    "&:focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#8AB528',
    },
    "&:focus-within .MuiOutlinedInput-notchedOutline": {
      borderColor: '#8AB528',
    },
    '.css-sva3xt-MuiAutocomplete-root .MuiOutlinedInput-root ':{
      padding: 0,     
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      paddingLeft: 'calc(20/1920*100vw)',
    },
    '& MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      backgroundColor: 'transparent',
      filter: 'brightness(0) saturate(100%) invert(87%) sepia(7%) saturate(27%) hue-rotate(324deg) brightness(86%) contrast(94%)',
      '&:hover': {
        filter: 'brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%) hue-rotate(37deg) brightness(99%) contrast(83%)'
      },
      'svg': {
        width: '24px',
        height: '24px',
      },
    },
    '&.MuiAutocomplete-root':{
    '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root':{
      width:'100%',
      height:'3.1vw', 
      fontSize: '0.83vw',
       lineHeight: 'calc(24/1920*100vw)',           
    },
    '.MuiPopper-root': {
      backgroundColor: 'black',
    },
    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input ': {
     padding:0,
     margin:0
    },
    '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {      
      fontSize: '0.83vw',
      lineHeight: 'calc(24/1920*100vw)',
      height:"3.1vw",      
    },
    '.css-1nwt07p-MuiAutocomplete-root .MuiOutlinedInput-root': {
      padding: '0.5vw'
    },
    '.css-sva3xt-MuiAutocomplete-root .MuiOutlinedInput-root': {
      padding: 0
    },
    

  },
}));

const CastStyle = {
  height:'3.1vw !important',
  fontFamily: 'IBM Plex Sans !important',
  fontWeight: '400 !important',
  fontSize: '0.83vw !important',   
  '.MuiInputBase-root' : {
    height:'3.1vw !important',
    fontFamily: 'IBM Plex Sans !important',
    fontWeight: '400 !important',
    fontSize: '0.83vw !important',
    borderRadius: '0',
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #BDBDBD',
    },
    "&:focused .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #8ab528',
    },
    "&:focus-within .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #8ab528',
      
    },
  }
}

const NewDocument = ({onSend}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSigned, setIsOpenSigned] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [pluginError, setPluginError] = useState(null);
  const [sendError, setSendError] = useState(false);

  const [value, setValue] = useState(null);
  const [packName, setPackName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [documents, setDocuments] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [sameFilesError, setSameFilesError] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [archive, setArchive] = useState('');
  const [recipientId, setRecipientId] = useState('');
  const [userLinks, setUserLinks] = useState([]);
  const [counterparties, setCounterparties] = useState([]);

  const [autocompleteStatus,setAutocompleteStatus] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const ACCEPT_COUNTER_PARTY_STATUS = 6;
  const MAX__LENGTH = 250;

   useEffect(() => {
    const getCounterparties = async () => {
      const res = await api.get('/get-counterparties');
      if (res?.data) {
        setUserLinks(res.data);
        setCounterparties(res.data.filter(item => (item.counterparty_status_id === ACCEPT_COUNTER_PARTY_STATUS && 
          item.my_status_id === ACCEPT_COUNTER_PARTY_STATUS)).map((item) => item ?? 'no name'));
      }
    };
    void getCounterparties();
  }, [])

  const MAX__SIZE = 47185920;
  const packageSize = documents.reduce((acc, item)=> acc + item.size, 0) 

  const {
    setCurrentTab,
    setCurrentPageTab
  } = useContext(TabsContext);
  
  const handleClick = () => {
      setCurrentTab(COUNTER_TAB_INDEX);
      setCurrentPageTab(0)
    }

  const uploadFile = (event) => {
    const files = Array.from(event.target.files);
    let newDocuments = [...documents];
    let hasSizeError = false;
    let hasSameFilesError = false;

    files.forEach(file => {
      const isUniqueFile = !newDocuments.some((document) => document?.name === file?.name);
      if (isUniqueFile && (packageSize + file.size < MAX__SIZE)) {
        newDocuments.push(file);
      } else if (isUniqueFile && (packageSize + file.size > MAX__SIZE)) {
        hasSizeError = true;
      } else if (!isUniqueFile) {
        hasSameFilesError = true;
      }
    });
    if (hasSizeError) {
      setSizeError(true);
    } else {
      setSizeError(false);
    }
    if (hasSameFilesError) {
      setSameFilesError(true);
    } else {
      setSameFilesError(false);
    }
    setDocuments(newDocuments);
    event.target.value = null;
  }
  const removeFile = (itemIndex) => {
    setDocuments(documents.filter((_, index) => itemIndex !== index));
    clearErrors();
  }
  const soldCheckbox = () => {
    setCheckBox((prevState) => !prevState);
  }
  const clearErrors = () => {
    setSizeError(false);
    setSameFilesError(false);
  }
  const submitClean = () =>{
    setValue(null)
    setPackName('');
    setDocuments([])
    setCheckBox(false)
    clearErrors();
    setRecipientId('');
  }

  return (
      <>
        <div className='new-document'>
        <h1>Отправить пакет документов на подпись</h1>
        <h3>1. Выберите контрагента-получателя:</h3> 
        {
          counterparties?.length ? 
          <>
            <p style={{paddingBottom:'5px'}}>Если Вы не нашли нужного получателя, добавьте его в разделе <a style={{textDecoration:"underline",cursor:"pointer"}} onClick={handleClick}>«Контрагенты»</a></p>
            <ThemeProvider
              theme={theme}>             
              <CustomAutocomplete
                  popupIcon={<DownArrow />}
                  clearIcon={autocompleteStatus?false:<DeleteIcon/>}
                  disableClearable={!value}
                  disablePortal   
                  onOpen={()=>setAutocompleteStatus(true)}    
                  onClose={()=>setAutocompleteStatus(false)}    
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    const linkObject = userLinks.find((item) => item.counterparty_name === newValue)                  
                    setRecipientId(linkObject?.counterparty_id)
                  }}
                  
                  inputValue={inputValue}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  blurOnSelect
                  id="combo-box-demo"

                  options={counterparties.map((item)=> item.counterparty_name)} 

                  sx={{ width: 'calc(1600/1920*100vw)', borderRadius: '0px'}}
                  renderInput={(params) => <TextField {...params} sx={{ width: '100%', height: "3.1vw", padding:0, margin: 0,fontSize:'1vw',}}  placeholder="Введите название или БИН контрагента" />}
                  filterOptions={()=>{
                    let newFilter = counterparties.filter((elem)=> {
                      return elem.counterparty_name.includes(inputValue) || elem.counterparty_identification_number.includes(inputValue)
                    }).map((elem)=> elem.counterparty_name)

                    return newFilter 
                  }}
              />
              </ThemeProvider>
          </>            
          :
          <div className='valid-error' style={{maxWidth:'100%'}}>
            У вас еще нет ни одного контрагента для электронного документооборта. <a style={{textDecoration:"underline",cursor:"pointer"}} onClick={handleClick}>Пригласить</a>
          </div>
        }         
        
        <h3 style={{marginBottom:'-9px'}}>2. Загрузите документы:</h3>   
        <List
          sx={{mt:'1.04vw'}}
          >
              {documents.map((elem,index) => {               
                return (
                  <ListItem                   
                    sx={{
                      backgroundColor:'#fff',
                      width: 'calc(1600/1920*100vw)',
                      height:'3.1vw',
                      borderBottom:'0.2px solid #E5E5E5',
                      '&:last-of-type': {
                        mb: '1.04vw',
                      }
                    }}
                    key={elem.size+index}
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={()=>removeFile(index)}
                        disableRipple
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                            filter: 'brightness(0) saturate(100%) invert(64%) sepia(17%) saturate(1697%) hue-rotate(37deg) brightness(99%) contrast(83%)'
                          }
                        }}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    }
                  >
                    <Typography sx={{fontSize:"0.83vw"}}>{elem.name}</Typography>
                  </ListItem>
                )})}
            </List>
        <CustomButton
        variant='contained'
        component='label'
        disableRipple
        startIcon={<ClipIcon/>}
        >
          <input
            onChange={uploadFile}
            type="file"          
            hidden
            multiple={'multiple'}
          />
          
          {documents.length?' Загрузить еще документ' :'Загрузить документ'}
        </CustomButton>
        <span>Всего не более 45 Мб в одном пакете</span>
        { sizeError && <div style={{width:'20%'}} className='valid-error'>Превышен максимально допустимый размер файлов – 45Мб</div>}
        { sameFilesError && <div style={{width:'20%'}} className='valid-error'>Невозможно загрузить одинаковые файлы</div>}
        <h3>3. Назовите этот пакет, чтобы ваш получатель мог быстрее увидеть и подписать его:</h3>    
        <p style={{paddingBottom:'5px'}}>Введите название пакета</p> 
        { packName.length>=MAX__LENGTH && <div style={{width:'20%'}} className='valid-error'>Максимальное количество символов 250</div>}
        <CustomInput 
        inputProps={{ maxLength: 250 }}
          label="Введите название пакета документов"
          sx = {CastStyle}
          variant="outlined"
          value={packName}
          onChange={(e) => {
            e.preventDefault()
            setPackName(e.target.value);
          }}
        />
        <div className='new-document__checkbox' onClick={soldCheckbox}>
          <Checkbox 
          checked={checkbox}
          />
          <span>Запросить подпись контрагента</span>
        </div>
        <div className='new-document__button-block'>
          {(!value || !packName || !documents.length) ? 
              <button
                className="form-button button-signature__empty"
                type="submit"
                disabled
              >
                Подписать и отправить        
              </button>
          :
              <button
                className="form-button button-signature"
                type="submit"
                onClick={async () => {
                        (pluginError) ? setIsOpenError(!isOpen) : setIsOpen(!isOpen);
                        const zip = new JSZip();
                        documents.forEach(async (document) => {
                          zip.file(`${document.name}`, document, {binary: true})
                        });
                        const blobArchive = await zip.generateAsync({type:"blob"});
                        setArchive(blobArchive);
                }}
              >
                Подписать и отправить        
              </button>
          }
          <button
           className={(documents.length || packName || checkbox || value)?"form-button button-cancel__secondary":"form-button button-cancel button-cancel__new-document"}
            onClick={submitClean}
          >
                Очистить форму     
          </button>
        </div>
      </div>
      <ModalSign
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsOpenSigned={setIsOpenSigned}
        setPluginError={setPluginError}
        submitClean={submitClean}
        value={value}
        packName={packName}
        archive={archive}
        checkbox={checkbox}
        recipientId={recipientId}
        setSendError={setSendError}
        refetchData={onSend}
        setIsOpenWaiting={setIsOpenWaiting}
      />
      <ModalWaiting
        isOpen ={isOpenWaiting} 
        setIsOpen={setIsOpenWaiting}
      />
      <ModalSignSending
        isOpen ={isOpenSigned} 
        setIsOpen={setIsOpenSigned} 
        sendError={sendError}
      /> 
      <ModalError
        isOpen ={isOpenError} 
        setIsOpen={setIsOpenError} 
      /> 
      </>
      
  );
};
export default NewDocument;
