import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import * as crypto from 'crypto-pro';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import api from '../../api/api';
import DeleteIcon from '../svgComponents/DeleteIcon';
import SignatureCheckedFalse from '../svgComponents/SignatureCheckedFalse';
import SignatureCheckedTrue from '../svgComponents/SignatureCheckedTrue';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '62.31vw',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans',
    boxSizing: 'border-box',
    outline: 'none'
};

export default function ModalSign(
    {
        isOpen,
        setIsOpen,
        setIsOpenSigned,
        setIsOpenWaiting,
        setPluginError,
        submitClean,
        value,
        packName,
        archive,
        checkbox,
        recipientId,
        setSendError,
        documentsID,
        setDocumentsID,
        uncheckAll,
        setDocsCount,
        readFileRecursive,
        refetchData,
        refetch
    }) {

    const [checked, setChecked] = useState(false);
    const [signThumbprint, setSignThumbprint] = useState(null);
    const [, setSignatureStatus] = useState('Не создана');
    const [certs, setCerts] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        void initCrypto();
        void getUserCertificates();
    }, []);

    useEffect(() => {
        setIsDisabled(!(certs.length));
    }, [certs]);

    // Функция для отправки документа на бэкенд
    const sendDocument = async (signature) => {
        const formData = new FormData();
        formData.append('recipient_name', value);
        formData.append('document_name', packName);
        formData.append('recipient_id', recipientId);
        formData.append('archive', signature);
        formData.append('is_sign_required', checkbox);
        try {
            await api.post("/send-document", formData);
            await refetchData();
            setSendError(false);
            setIsOpenWaiting(false);
            setIsOpenSigned(true);
        } catch (e) {
            setSendError(true);
            setIsOpenWaiting(false);
            setIsOpenSigned(true);
            console.error(e);
        }
    }

    // Вспомогательная функция для получения содержимого "большого" архива
    const requestFileToSign = async (documentID) => {
        const res = await api.get(`/download?documentID=${documentID}`);
        if (res?.data) {
            const file = res.data.file;
            return { file: file, currentDocument: res?.data.document };
        }
    }

    const getKpp = (description) => {
        const regex = /KPP=(\d+)/;
        const match = description.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return '';
        }
    }

    const getObjectDescriptionByTitle = (certsInfoArray, objectTitle) => {
        const currentObject = certsInfoArray.find((item) => item.title === objectTitle);
        return currentObject?.description || '';
    }

    const getMonthByNumber = (monthNumber) => {
        switch (monthNumber) {
            case 1: return 'января';
            case 2: return 'февраля';
            case 3: return 'марта';
            case 4: return 'апреля';
            case 5: return 'мая';
            case 6: return 'июня';
            case 7: return 'июля';
            case 8: return 'августа';
            case 9: return 'сентября';
            case 10: return 'октября';
            case 11: return 'ноября';
            case 12: return 'декабря';
            default: return 'неверный месяц';
        }
    }

    const transformCertDate = (stringDate) => {
        const objDate = new Date(stringDate);
        const day = objDate.getDate().toString();
        const month = getMonthByNumber(objDate.getMonth() + 1);
        const year = objDate.getFullYear();
        return day + ' ' + month + ' ' + year;
    }


    // Функция для инициализации плагина
    const initCrypto = async () => {
        try {
            await crypto.getSystemInfo();
        }
        catch (error) {
            setPluginError(error.message);
        }
    };

    // Функция для получения доступных сертификатов
    const getUserCertificates = async () => {
        const receivedCerts = await crypto.getUserCertificates();
        const modifiedCerts = await Promise.all(receivedCerts.map(async (receivedCert) => {
        const subjectInfoArray = await receivedCert.getOwnerInfo();
        const inn = getObjectDescriptionByTitle(subjectInfoArray, 'ИНН') || 'Не найдено';
        const owner = getObjectDescriptionByTitle(subjectInfoArray, 'Владелец') || 'Не найдено';
        const kpp = getKpp(getObjectDescriptionByTitle(subjectInfoArray, 'Неструктурированное имя')) || 'Не найдено';
        const expiredDate = transformCertDate(receivedCert.validTo) || 'Не найдено';

        return {
            ...receivedCert,
            inn,
            owner,
            kpp,
            expiredDate
        };
        }));

        setCerts(modifiedCerts);
    };

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    // Рекурсивная функция для последовательной подписи пакетов документов
    const signFileRecursive = async (arr) => {
        setDocsCount(arr.length);
        if (!arr.length) {
            setIsOpenWaiting(false);
            setIsOpenSigned(true);
            return;
        }
        const currentDocumentID = arr.at(-1);
        const response = await requestFileToSign(currentDocumentID);
        const fileName = response.currentDocument.document_name.replaceAll(/[/]/g, "_");

        const secondZip = new JSZip();
        const file = await b64toBlob(response.file);
        secondZip.file(`${fileName}`, file, { binary: true });
        const zipForSign = await secondZip.generateAsync({ type: "blob" });
        const arrayBufferBlob = await zipForSign.arrayBuffer();

        setSignatureStatus('Создается...');

        try {
            const createdAttachedSignature = await crypto.createAttachedSignature(signThumbprint, arrayBufferBlob);
            const formData = new FormData();
            formData.append('archive', createdAttachedSignature);
            formData.append('document_name', fileName);
            formData.append('document_id', currentDocumentID);
            formData.append('recipient_id', response.currentDocument.sender_id);
            const res = await api.post("/update-document", formData);
            if (res?.data) {
                const newDocumentId = res.data.send_res.transfer_meta.document_id;
                await api.post(`/sign?documentID=${newDocumentId}`);
                await refetch("/incoming-documents");
            }

        } catch (error) {
            console.log('Ошибка создания подписи', error.message)
        } finally {
            setSignatureStatus('Не создана');
        }
        return signFileRecursive(arr.slice(0, -1));
    }

    // Функция для подписания пакета/пакетов документов
    const onSign = async (e) => {
        e.preventDefault();
        setSignThumbprint(null);
        // Проверка, откуда вызывается (true при "+Новый", false при "Входящие")
        if (submitClean) {
            // Создание arrayBuffer из blob
            const arrayBufferBlob = await archive.arrayBuffer();
            setSignatureStatus('Создается...');
            // Создание присоединенной подписи
            try {
                setIsOpenWaiting(true);
                setIsOpen(false);
                const createdAttachedSignature = await crypto.createAttachedSignature(signThumbprint, arrayBufferBlob);
                sendDocument(createdAttachedSignature);
                submitClean();
            } catch (error) {
                console.log('Ошибка создания подписи', error.message)
            } finally {
                setSignatureStatus('Не создана');
                return;
            }
        } else {
            if (signThumbprint) {
                readFileRecursive(documentsID)
                void signFileRecursive(documentsID);
                setIsOpen(false);
                setIsOpenWaiting(true);
                setDocumentsID([]);
                uncheckAll();
            }
        }
    }

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='signature-close' onClick={() => setIsOpen(false)}><DeleteIcon /></div>
                    <div className='signature-choose'>Выбрать сертификат для подписи:</div>
                    <div className='signature-center'>
                        {!(certs.length) && (
                            <div className='signature-choose'>
                                Сертификаты не найдены <br/>
                                Добавьте новый, либо предоставьте доступ CryptoPro CSP выполнять операции с сертификатами
                            </div>
                        )}
                        {certs?.map((cert) => {
                            return <div
                                key={cert.thumbprint}
                                onClick={() => {
                                    setSignThumbprint(cert.thumbprint)
                                    setChecked(!checked)
                                }
                                }
                                className={(cert.thumbprint === signThumbprint) ? 'signature-cert-selected' : 'signature-cert'}
                            >
                                <div className='signature-cert__left-block'>
                                    <div className='signature-cert__checkbox-icon'>
                                        {(cert.thumbprint === signThumbprint) ? <SignatureCheckedTrue /> : <SignatureCheckedFalse />}

                                    </div>
                                    <div>
                                        <div className={(cert.thumbprint === signThumbprint) ? 'signature-cert__name-selected' : 'signature-cert__name'}>
                                            {cert.name}
                                        </div>
                                        <div className='signature-cert__signer'>
                                            {cert.owner}
                                        </div>
                                        <div className='signature-cert__valid'>
                                            <span className="signature--gray">Годен до:</span> {cert.expiredDate}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span className="signature--gray">ИНН</span> {cert.inn}
                                    </div>
                                    <div style={{ height: '0.53vw' }} />
                                    <div>
                                        <span className="signature--gray">КПП</span> {cert.kpp}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className='signature-buttons'>
                        {!(certs.length) ?
                            <button
                                onClick={onSign}
                                className='signature-sign__empty'
                                style={{marginRight: '2.15vw'}}
                                disabled={isDisabled}
                            >
                                Подписать
                            </button>
                            :
                            <button
                                onClick={onSign}
                                className='signature-sign signature-button--expanded'
                                style={{marginRight: '2.15vw'}}
                                disabled={isDisabled}
                            >
                                Подписать
                            </button>
                        }
                        <div
                            onClick={() => setIsOpen(false)}
                            className='signature-cancel signature-button--expanded'
                        >
                            Отменить
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
};