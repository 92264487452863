import React, { useContext, useEffect, useMemo, useState } from "react";
import * as fileSaver from 'file-saver';
import JSZip from 'jszip';
import PropTypes from "prop-types";
import ASN1 from '@lapo/asn1js';
import Base64 from "@lapo/asn1js/base64";
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import { PDFDocument } from 'pdf-lib';

import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import api from "../api/api";
import { TabsContext } from "../helpers/TabsContext";
import Badge from '../component/svgComponents/Badge'

import Search from "./Search";
import ButtonsPanelIncoming from "./DocumentComponents/ButtonsPanelInComing";
import ButtonsPanelOutGoing from "./DocumentComponents/ButtonsPanelOutGoing";
import NewDocument from "./DocumentComponents/NewDocument";
import TableDocument from "./DocumentComponents/TableDocument";
import NoDocumentInComing from "./DocumentComponents/NoDocumentInComing";
import NoDocumentOutGoing from "./DocumentComponents/NoDocumentOutGoing";
import Loader from "./DocumentComponents/Loader";
import { checkAbleToSignOrDeclineStatus, checkAbleToRevalidateStatus } from "../helpers";
import StampTemplate from "./StampTemplate";
import {OVERDUE_DOCUMENT} from "./DocumentComponents/StatusDocument";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: (
        <span
          style={{ width: "73%", background: "#8AB528", zIndex: 503 }}
          className="MuiTabs-indicatorSpan"
        />
      ),
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    width: "5.9vw",
    height: "0.52vw",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    maxWidth: "100%",
    zIndex: 504,
    marginLeft: "-1.5vw",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    height: "5.2vw",
    fontSize: "1.25vw",
    fontFamily: "IBM Plex Sans",
    paddingLeft: 0,
    paddingRight: "3vw",
    color: "#181818",
    "&.Mui-selected": {
      color: "#181818",
      fontWeight: "700",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const customStyleUndo = {
  marginTop: "1.04vw",
};
const customStyleUpper = {
  marginTop: "1.04vw",
  paddingBottom: "6.5vw",
};

const rows = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={!rows.length ? customStyleUndo : customStyleUpper}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LabTabsDocument() {
  const { currentPageTab, setCurrentPageTab } = useContext(TabsContext);
  const [searchValue, setSearchValue] = useState({
    incoming: "",
    outgoing: "",
  });
  const [signOrDeclineButtonActive, setSignOrDeclineButtonActive] = useState(true);
  const [revalidateButtonActive, setRevalidateButtonActive] = useState(true);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const INCOMING = 1;
  const OUTGOING = 2;

  const VALIDATED = 2;
  const NOT_VALIDATED = 3;

  const handleChange = (event, newValue) => {
    uncheckAll();
    setDocumentsID([]);
    localStorage.setItem('Tab', JSON.stringify({"currentTab":0,"currentPageTab":newValue}));
    setCurrentPageTab(newValue);
  };
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  const getFileContent = (asn) => {
    const fileContent = asn.sub[1].sub[0].sub[2].sub[1].sub[0];
    const file = asn.stream.enc.slice(fileContent.posStart(), fileContent.posEnd());
    return file;
  }

  const getCertificates = (asn) => {
    const certificates = [];
    asn.sub[1].sub[0].sub[3].sub.forEach((item) => {
      const base64URL = item.toB64String();
      const base64 =
        '-----BEGIN CERTIFICATE-----\n'
        + base64URL.replaceAll('-', '+').replaceAll('_', '/')
        + '\n-----END CERTIFICATE-----';
      certificates.push(base64);
    });
    return certificates;
  }

  const formatDate = (date, withTime) => {
    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const year = date.getFullYear();

    if (withTime) {
      const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
      const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
      const second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();

      return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    }

    return `${day}.${month}.${year}`;
  }

  const getDataAboutSigner = (userData) => {
    const signerInfo = {};
    userData.sub.forEach((tag) => {
      const code = tag.sub[0].sub[0].content().split('\n')[1];
      const content = tag.sub[0].sub[1].content();

      switch (code) {
        case 'title':
          signerInfo.position = content;
          break;
        case 'organizationName':
          signerInfo.orgName = content;
          break;
        case 'givenName':
          signerInfo.fullName = signerInfo.fullName
            ? `${signerInfo.fullName} ${content}`
            : content;
          break;
        case 'surname':
          signerInfo.fullName = signerInfo.fullName
            ? `${content} ${signerInfo.fullName}`
            : content;
          break;
        default:
      }
    })

    let res = '';
    Object.keys(signerInfo).forEach((key) => {
      if (res) {
        if (key === 'fullName') res = `${signerInfo[key]}, ${res}`;
        else res += `, ${signerInfo[key]}`;
      } else {
        res = signerInfo[key];
      }
    })
    return res ? { userData: res } : {};
  }

  function dec2hex(str){ // .toString(16) only works up to 2^53
    var dec = str.toString().split(''), sum = [], hex = [], i, s
    while(dec.length){
      s = 1 * dec.shift()
      for(i = 0; s || i < sum.length; i++){
        s += (sum[i] || 0) * 10
        sum[i] = s % 16
        s = (s - sum[i]) / 16
      }
    }
    while(sum.length){
      hex.push(sum.pop().toString(16))
    }
    return hex.reduce((res, value, index) => index % 2 ? `${res}${value.toUpperCase()}` : `${res} ${value.toUpperCase()}`)
  }

  const getSignerInfo = (asn, result_code) => {
    try {
      const certificate = asn.sub[1].sub[0].sub[3].sub.at(-1).sub[0];
      const signerInfo = {...getDataAboutSigner(certificate.sub[3]), ...getDataAboutSigner(certificate.sub[5])};
      signerInfo.title = 'Файл подписан электронной подписью';
      const startDate = new Date(certificate.sub[4].sub[0].content());
      const endDate = new Date(certificate.sub[4].sub[1].content());
      const signAttrs = asn.sub[1].sub[0].sub[4].sub[0].sub[3];
      let signDate;
      signAttrs.sub.forEach((tag) => {
        if (tag.sub[0].content().split('\n')[1] === 'signingTime')
          signDate = new Date(tag.sub[1].sub[0].content());
      });
      signerInfo.validFrom = formatDate(startDate);
      signerInfo.validTo = formatDate(endDate);
      signerInfo.date = signDate;

      signerInfo.serialNumber = dec2hex(certificate.sub[1].content().split('\n')[1]);
      signerInfo.result = !result_code;
      return signerInfo;
    } catch (ignored) {
    }
  }

  const generateStamp = (asns) => {
    const signerInfo = {};
    Object.keys(asns).forEach((user) => signerInfo[user] = asns[user] && asns[user]
      .filter(a => (a.sub && a.sub.length>1) || (a.data && a.data.sub.length>1))
      .map((document) => {
        return document.hasOwnProperty('data')
          ? getSignerInfo(document.data, document.code)
          : getSignerInfo(document, 0);
      }));

    const stampDOM = ReactDOMServer.renderToString(<StampTemplate {...signerInfo}/>);
    const content = document.createElement('div');
    content.innerHTML = stampDOM;

    const options = {
      margin: 10,
      filename: 'stamp',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    return html2pdf().from(content).set(options).output('blob');
  }

  const getSignedFilesArchive = async (asn, baseFile, sTickets, rTickets, workflowId) => {
    const zip = new JSZip();
    await zip.loadAsync(getFileContent(baseFile ? baseFile : asn));
    const resZip = new JSZip();

    sTickets = sTickets && sTickets.map((ticket) => ({
      data: ASN1.decode(Base64.unarmor(ticket.data)),
      code: ticket.result_code
    }));
    rTickets = rTickets && rTickets.map((ticket) => ({
      data: ASN1.decode(Base64.unarmor(ticket.data)),
      code: ticket.result_code
    }));
    const stamps = await generateStamp({
      sender: baseFile ? [baseFile, ...sTickets] : [asn, ...sTickets],
      recipient: baseFile ? [asn, ...rTickets] : null
    });

    for (let [filename, file] of Object.entries(zip.files)) {
      let extension = filename.split('.');
      extension = extension[extension.length - 1];
      let fileB64 = await file.async('base64');

      if (extension === 'pdf') {
        const mergedPDF = await PDFDocument.create();
        let filePDF = await PDFDocument.load(await file.async('Uint8array'));
        filePDF = await mergedPDF.copyPages(filePDF, filePDF.getPageIndices());
        filePDF.forEach((page) => {
          mergedPDF.addPage(page);
          page.drawText(
            `Document ID: ${workflowId}`,
            {
              x: 30,
              y: 20,
              size: 10,
            }
          );
        });

        let sStampPDF = await PDFDocument.load(await stamps.arrayBuffer());
        sStampPDF = await mergedPDF.copyPages(
          sStampPDF,
          sStampPDF.getPageIndices(),
        );
        sStampPDF.forEach((page) => {
          mergedPDF.addPage(page);
          page.drawText(
            `Document ID: ${workflowId}`,
            {
              x: 30,
              y: 20,
              size: 10,
            }
          );
        });

        fileB64 = await mergedPDF.save();
      } else {
        fileB64 = atob(fileB64);
      }
      resZip.file(filename.replaceAll(/[/]/g, "_"), fileB64, {binary: true});
    }
    return await resZip.generateAsync({type: 'blob'});
  }

  const downloadFileRecursive = async (arr) => {
    if (!arr.length) {
      return;
    }
    const res = await api.get(`/download?documentID=${arr.at(-1)}`);
    if (res?.data) {
      const zip = new JSZip();
      const asn = ASN1.decode(Base64.unarmor(res?.data.file));
      const fileName = res?.data.document.document_name;
      const senderTickets = res?.data.senderTickets;
      const recipientTickets = res?.data.recipientTickets;

      let baseFile = getFileContent(asn);
      let subFile = await JSZip.loadAsync(baseFile);

      const filenames = Object.keys(subFile.files);
      const normalize = (name) => name.replaceAll(/[/\\_]/g, "");

      // Файл является архивом если получен 1 файл и у него нет расширения
      const isArchive =
        (
          filenames.length === 1 &&
          filenames[0].split('.').length === 1
        ) ||
        normalize(fileName) === normalize(filenames[0]);

      // Файл является вложенным архивом если получено несколько файлов,
      // но только один из них не является папкой и его расширение не pdf
      const isNested =
          filenames.filter(
            (item) => !subFile.files[item].dir
          ).length === 1
          && filenames.length > 1
          && filenames.find((item) =>
            !subFile.files[item].dir
          ).split('.').at(-1) !== 'pdf';

      //Получение внутренностей архива и парсинг asn
      const getFileAsn = async (filename) =>
        ASN1.decode(
          Base64.unarmor(
            await subFile.files[filename].async('base64')
          )
        );

      if (isArchive) {
        // Получение подписанных данных из архива
        subFile = await getFileAsn(filenames[0]);
        // Получение изначального файла
        baseFile = getFileContent(subFile);
      } else if (isNested) {
        const realFileName = filenames.find((item) =>
          !subFile.files[item].dir
        );
        // Получение подписанных данных из вложенного архива
        subFile = await getFileAsn(realFileName);
        // Получение изначального файла
        baseFile = getFileContent(subFile);
      } else if (asn.sub[1].sub[0].sub[2].sub[1].sub[0].sub) {
        subFile = asn.sub[1].sub[0].sub[2].sub[1].sub[0].sub[0];
        baseFile = getFileContent(subFile);
      } else {
        subFile = null;
      }
      baseFile = await (
        await JSZip.loadAsync(baseFile)
      ).generateAsync({type: 'blob'});

      const signedFile = getSignedFilesArchive(
        asn,
        subFile,
        senderTickets,
        recipientTickets,
        res.data.document.ext_workflow_id
      );

      const certificates = subFile
        ? [ ...getCertificates(subFile), ...getCertificates(asn)]
        : getCertificates(asn);
      const signatures = subFile
        ? [subFile.stream.enc, asn.stream.enc]
        : [asn.stream.enc];
      zip.file('Исходные файлы.zip', baseFile, {binary: true});
      zip.file('Архив с данными подписи.zip', signedFile, {binary: true});

      let documentsZip = new JSZip();
      certificates.forEach((item, index) => documentsZip.file(`Сертификат_${index + 1}.crt`, item));
      signatures.forEach((item, index) => documentsZip.file(`Подпись_${index + 1}.sig`, item));

      if (senderTickets || recipientTickets) {
        try{
          if (senderTickets)
            senderTickets.forEach((ticket, index) => {
              documentsZip.file(`Квитанция_отправителя_${index + 1}.sig`, atob(ticket.data), {binary: true});
            })
          if (recipientTickets)
            recipientTickets.forEach((ticket, index) => {
              documentsZip.file(`Квитанция_получателя_${index + 1}.sig`, atob(ticket.data), {binary: true});
            })
        } catch (e) {
          console.log('Ошибка получения квитанций, ', e);
        }
      }

      documentsZip = await documentsZip.generateAsync({type:"blob"});
      zip.file('Подпись,квитанции,сертификаты.zip', documentsZip);
      const archiveWithTickets = await zip.generateAsync({type:"blob"});
      fileSaver.saveAs(archiveWithTickets, fileName.includes('.') ? `${fileName}.zip` : fileName);
    }
    return downloadFileRecursive(arr.slice(0, -1));
  }

  const declineFileRecursive = async (arr) => {
    if (!arr.length) {
      return;
    }
    await api.post(`/decline?documentID=${arr[0]}`);
    await refetch("/incoming-documents");
    return declineFileRecursive(arr.slice(1));
  }

  const deleteFileRecursive = async (arr) => {
    if (!arr.length) {
      return;
    }
    await api.post(`/delete?documentID=${arr[0]}`);
    if (currentPageTab === INCOMING) {
      await refetch("/incoming-documents");
    }
    if (currentPageTab === OUTGOING) {
      await refetch("/outgoing-documents");
    }
    return deleteFileRecursive(arr.slice(1));
  }

  const revalidateFileRecursive = async (arr) => {
    if (!arr.length) {
      return;
    }
    const res = await api.post(`/get?documentID=${arr[0]}`);
    if (res?.data?.sender_signature_status_id === NOT_VALIDATED
      || res?.data?.sender_signature_status_id === VALIDATED) {
      await api.post(`/revalidate?documentID=${arr[0]}`);
      await refetch("/outgoing-documents");
    }
    return revalidateFileRecursive(arr.slice(1));
  }

  const readFileRecursive = async (arr) => {
    if (!arr.length) {
      return;
    }
    if (currentPageTab === INCOMING) {
      await api.post( `/read-document/?id=${arr.at(-1)}&user=recipient`);
    }
    if (currentPageTab === OUTGOING) {
      await api.post( `/read-document/?id=${arr.at(-1)}&user=sender`);
    }
    return readFileRecursive(arr.slice(0, -1));
  }

  const downloadFiles = async () => {
    try {
      setIsDownloading(true);
      await readFileRecursive(documentsID);
      await downloadFileRecursive(documentsID);
      setIsDownloading(false);
      setDocumentsID([]);
      uncheckAll();
    } catch (e) {
      setIsDownloading(false);
    }
  }

  const declineFiles = async () => {
    await readFileRecursive(documentsID);
    void declineFileRecursive(documentsID);
    setDocumentsID([]);
    uncheckAll();
  }

  const deleteFiles = async () => {
    await readFileRecursive(documentsID);
    void deleteFileRecursive(documentsID);
    setDocumentsID([]);
    uncheckAll();
  }

  const revalidateFiles = async () => {
    await readFileRecursive(documentsID);
    void revalidateFileRecursive(documentsID);
    setDocumentsID([]);
    uncheckAll();
  }

  const [incomingDocument, setIncomingDocument] = useState([]);
  const [incomingFilteredDocument, setIncomingFilteredDocument] = useState([]);

  const [outgoingDocument, setOutgoingDocument] = useState([]);
  const [outgoingFilteredDocument, setOutgoingFilteredDocument] = useState([]);

  const [documentsID, setDocumentsID] = useState([]);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const [countIncoming, setCountIncoming] = useState(0);
  const [countOutgoing, setCountOutgoing] = useState(0);
  const refetch = async (uri) => {
    await api.get('/get-list');
    const res = await api.get(uri);
    const responseData = res?.data;
    const serialiseObject = (data) => {
      return {
        id: data.ext_document_id,
        date: data.date,
        document: data.document_name,
        recipientDocumentStatus: data.recipient_document_status.name,
        senderDocumentStatus: data.sender_document_status.name,
        recipientSignatureStatus: data.recipient_signature_status?.name ?? "",
        senderSignatureStatus: data.sender_signature_status.name,
        shelfLife: data.expires_at,
        checked: false,
        workflowId: data.ext_workflow_id,
        counterpartyIdentificationNumber: data.counterparty_identification_number,
      }
    }

    if (responseData) {
      setIsContentLoaded(true);
      if(uri === '/incoming-documents') {
        setCountIncoming((responseData.filter((item)=>item.is_read_recipient===false)).length)
        setIncomingDocument(
          responseData.map((data) => {
            const serializedObject = serialiseObject(data);
            return {
              ...serializedObject,
              company: data.sender_name,
              senderId: data.sender_id,
              is_read: data.is_read_recipient,
            };
          })
        );
      }

      if(uri === '/outgoing-documents') {
        setCountOutgoing((responseData.filter((item)=>item.is_read_sender===false)).length)
        setOutgoingDocument(
          responseData.map((data) => {
            const serializedObject = serialiseObject(data);
            return {
              ...serializedObject,
              company: data.recipient_name,
              is_read: data.is_read_sender,
            };
          })
        );
      }
      void refetch();
    }
    // ADD ERROR HANDLE
  }

  const incomingDocPath = "/incoming-documents";
  const outgoingDocPath = "/outgoing-documents";

  const currentTabDocsPath = useMemo(() =>
      (currentPageTab === INCOMING
        ? incomingDocPath
        : currentPageTab === OUTGOING
          ? outgoingDocPath
          : [incomingDocPath, outgoingDocPath]),
    [currentPageTab]
  );

  useEffect(() => {
    setIsContentLoaded(true)
    const asyncHandler = async (uri) => {
      await refetch(uri)
    }
    if (currentTabDocsPath instanceof Array) {
      Promise.all(currentTabDocsPath.map(async (uri) => {
        await asyncHandler(uri);
      }));
    } else {
      void asyncHandler(currentTabDocsPath);
    }
    setIsContentLoaded(false)
  }, [currentTabDocsPath]);

  useEffect(() => {
    const getUnreadAsync = async () => {
      const incomingRes = await api.get("/incoming-documents");
      const outgoingRes = await api.get("/outgoing-documents");

      const incomingResData = incomingRes?.data;
      const outgoingResData = outgoingRes?.data;

      setCountIncoming((incomingResData.filter((item)=>item.is_read_recipient===false)).length);
      setCountOutgoing((outgoingResData.filter((item)=>item.is_read_sender===false)).length);
    }

    void getUnreadAsync()
  }, [])

  const checkCallback = (documentID) => (prevState) => {
    return prevState.map((document) =>
      document.id === documentID
        ? { ...document, checked: !document.checked }
        : document
    );
  };

  const checkInComingDocument = (documentID) => {
    setIncomingDocument(checkCallback(documentID));
  };

  const checkOutGoingDocument = (documentID) => {
    setOutgoingDocument(checkCallback(documentID));
  };

  const uncheckAll = () => {
    const callback = (prevState) => {
      return prevState.map(document => ({...document, checked: false}))
    }
    setOutgoingDocument(callback);
    setIncomingDocument(callback);
  }

  useEffect(() => {
    setOutgoingFilteredDocument(outgoingDocument.filter((data) => {
      return Object.values(data).some(value => value?.toString().toUpperCase().includes(searchValue?.outgoing?.toUpperCase()))
    }))
  }, [searchValue.outgoing, outgoingDocument]);


  useEffect(() => {
    setIncomingFilteredDocument(incomingDocument.filter((data) => {
      return Object.values(data).some(value => value?.toString().toUpperCase().includes(searchValue?.incoming?.toUpperCase()))
    }))
  }, [searchValue.incoming, incomingDocument]);

  useEffect(() => {
    setSignOrDeclineButtonActive(checkAbleToSignOrDeclineStatus(incomingDocument));
  }, [incomingDocument])

  useEffect(() => {
    setRevalidateButtonActive(checkAbleToRevalidateStatus(outgoingDocument));
  }, [outgoingDocument])

  useEffect(() => {
    const checkedDocuments = outgoingDocument.filter(document => document.checked);
    const isOutgoingDisabled = checkedDocuments.some(document => {
      const senderStatus = document.senderDocumentStatus.toLowerCase();
      return senderStatus === OVERDUE_DOCUMENT.toLowerCase();
    })
    setIsButtonsDisabled(isOutgoingDisabled);
  }, [outgoingDocument]);

  useEffect(() => {
    const checkedDocuments = incomingDocument.filter(document => document.checked);
    const isIncomingDisabled = checkedDocuments.some(document => {
      const senderStatus = document.recipientDocumentStatus.toLowerCase();
      return senderStatus === OVERDUE_DOCUMENT.toLowerCase();
    })
    setIsButtonsDisabled(isIncomingDisabled);
  }, [incomingDocument]);

  return (
    <>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "calc(1600/1920*100vw)" }}>
          <StyledTabs
            value={currentPageTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <StyledTab label="+Новый"icon={false ? <Badge content={0}/> : null}
                       iconPosition="end" {...a11yProps(0)} />
            <StyledTab
              label="Входящие"
              icon={countIncoming ? <Badge content={countIncoming}/> : null}
              iconPosition="end"
              {...a11yProps(1)}
            />
            <StyledTab
              label="Исходящие"
              {...a11yProps(2)}
              icon={countOutgoing ? <Badge content={countOutgoing}/> : null}
              iconPosition="end"
            />
          </StyledTabs>
          <hr
            style={{
              height: "2pt",
              background: "#BDBDBD",
              border: "none",
              position: "relative",
              bottom: "2px",
              zIndex: 502,
            }}
          />
        </Box>
        <TabPanel value={currentPageTab} index={0}>
          <NewDocument onSend={async () => {
            await Promise.all(currentTabDocsPath.map(async (uri) => {
              await refetch(uri);
            }));
          }} />
        </TabPanel>
        <TabPanel value={currentPageTab} index={1}>
          {!incomingDocument?.length && !searchValue.incoming ? (
            <>
              {!isContentLoaded? <Loader />
                : <NoDocumentInComing />
              }
            </>
          ) : (
            <>
              <Search
                setSearchValue={setSearchValue}
                value={searchValue.incoming}
                onChange={(e) => {
                  setSearchValue(prevState => ({...prevState, incoming: e.target.value}));
                }}
              />
              <ButtonsPanelIncoming
                signOrDeclineButtonActive={signOrDeclineButtonActive}
                uncheckAll={uncheckAll}
                isButtonsDisabled={isButtonsDisabled}
                documentsID={documentsID}
                setDocumentsID={setDocumentsID}
                downloadFiles={async () => {
                  await downloadFiles();
                  await refetch(currentTabDocsPath)
                }}
                readFileRecursive={readFileRecursive}
                declineFiles={declineFiles}
                deleteFiles={deleteFiles}
                dataURLtoFile={dataURLtoFile}
                refetch={refetch}
                isDownloading={isDownloading}
              />
              <TableDocument
                checkDocument={checkInComingDocument}
                rows={searchValue.incoming ? incomingFilteredDocument : incomingDocument}
                documentsID={documentsID}
                setDocumentsID={setDocumentsID}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value={currentPageTab} index={2}>
          {!outgoingDocument.length  && !searchValue.outgoing ? (
            <>
              {!isContentLoaded? <Loader />
                : <NoDocumentOutGoing />
              }
            </>
          ) : (
            <>
              <Search
                setSearchValue={setSearchValue}
                value={searchValue.outgoing}
                onChange={(e) => {
                  setSearchValue(prevState => ({...prevState, outgoing: e.target.value}));
                }}
              />
              <ButtonsPanelOutGoing
                revalidateButtonActive={revalidateButtonActive}
                downloadFiles={async () => {
                  await downloadFiles();
                  await refetch(currentTabDocsPath)
                }}
                isButtonsDisabled={isButtonsDisabled}
                deleteFiles={deleteFiles}
                documentsID={documentsID}
                revalidateFiles={revalidateFiles}
                isDownloading={isDownloading}
              />
              <TableDocument
                checkDocument={checkOutGoingDocument}
                rows={searchValue.outgoing ? outgoingFilteredDocument : outgoingDocument}
                documentsID={documentsID}
                setDocumentsID={setDocumentsID}
              />
            </>
          )}
        </TabPanel>
      </Box>
    </>
  );
}

export default LabTabsDocument;