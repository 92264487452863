import React, {useEffect, useState, useContext, memo} from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

import { TabsContext } from '../../helpers/TabsContext';
import RightArrow from '../svgComponents/RightArrow';
import LeftArrow from '../svgComponents/LeftArrow';
import UncheckedCheckbox from '../svgComponents/UncheckedCheckbox';
import CheckedCheckbox from '../svgComponents/CheckedCheckbox';
import AlertIcon from '../svgComponents/AlertIcon'

import globalStyle from './customStyleButton';
import StatusDocument from './StatusDocument';
import WorkflowID from './WorkflowID';

function RowDocumentation({checkDocument, row, addToDocumentsID, removeFromDocumentsID}){
    const [checkbox, setCheckbox] = useState(false);
    useEffect(() => {
        setCheckbox(row.checked);
        row.checked
            ? addToDocumentsID(row.workflowId)
            : removeFromDocumentsID(row.workflowId);
        return () => setCheckbox(false);
    }, [row.checked])
    const {currentPageTab} = useContext(TabsContext);

    const changeCheckbox = () => {
        checkDocument(row.id);
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let date = new Date();
    date.setDate(date.getDate() + 3);
    

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            <TableCell sx={{width:'6%',verticalAlign:"baseline"}}>
                <Checkbox 
                disableRipple 
                checked={checkbox}
                onChange={changeCheckbox}
                icon={<UncheckedCheckbox />}
                checkedIcon={<CheckedCheckbox />}
                sx={{    
                    transform: `scale(${windowWidth/1920})`,
                    color: '#BDBDBD',
                    '&.Mui-checked': {
                    color: '#8AB528',
                    },
                }}
                />

            </TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'9%', fontWeight: row.is_read? '400':'600'}}>{row.date.split('T')[0].split('-').reverse().join('.')}</TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'16%',wordBreak:"break-word",fontWeight: row.is_read? '400':'600'}}>{row.company}</TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'24%',wordBreak:"break-word",fontWeight: row.is_read? '400':'600'}}>{row.document}{<WorkflowID workflowId={row.workflowId}/>}</TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'17%'}}>
                {currentPageTab === 1 ? 
                <StatusDocument 
                    documentStatus = {row?.recipientDocumentStatus?.toUpperCase()} 
                    senderSignatureStatus = {row?.senderSignatureStatus?.toLowerCase()}
                    recipientSignatureStatus = {row?.recipientSignatureStatus?.toLowerCase()}

                />
                : 
                <StatusDocument 
                    documentStatus = {row?.senderDocumentStatus?.toUpperCase()} 
                    senderSignatureStatus = {row?.senderSignatureStatus?.toLowerCase()}
                    recipientSignatureStatus = {row?.recipientSignatureStatus?.toLowerCase()}
                />}
                
                </TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'17%',fontWeight: row.is_read? '400':'600'}}>
               
                <div className='row__signature-status'>
                {currentPageTab===2 ?<RightArrow/>:<LeftArrow/>}
                    <span  style={{paddingLeft:"4px"}}>{row.senderSignatureStatus.toLowerCase()}</span>
                    {row.senderSignatureStatus.toLowerCase()=== "не валидирована"?<div className='alert'><AlertIcon/></div>:""}
                </div>
                
                <div className='row__signature-status'>
                    {row.recipientSignatureStatus && currentPageTab===2 && <LeftArrow/> || row.recipientSignatureStatus && <RightArrow/>}
                    <span 
                    style={{paddingLeft:"4px"}
                    }>
                    {row.recipientSignatureStatus.toLowerCase()}
                    </span>                    
                    {row.recipientSignatureStatus.toLowerCase()=== "не валидирована"?<div className='alert'><AlertIcon/></div>:""}
                </div>    

                </TableCell>
            <TableCell sx={{...globalStyle.customStyleTable, width:'13%',paddingLeft:'0',fontWeight: row.is_read? '400':'600'}} >
                <div className='date-out'>
                    <div><pre>до </pre></div>
                    {row.shelfLife.split('T')[0].split('-').reverse().join('.')}
                    {date>new Date(row.shelfLife) ?<div style={{paddingLeft:"4px"}} ><AlertIcon/></div> : ""}
                </div>
            </TableCell>
        </TableRow>
    );
}
export default memo(RowDocumentation)